{
  "auth": {
    "agree": "Я согласен с",
    "createAccount": "Создать аккаунт",
    "createNewAccount": "Создать новый аккаунт",
    "telephone": "телефон",
    "login": "Войти",
    "password": "Пароль",
    "create": "Создать аккаунт",
    "recover_password": "Восстановить пароль",
    "have_an_account": "Иметь аккаунт?",
    "sign_up": "Зарегистрироваться",
    "forgot_password": "Забыли пароль?",
    "keep_logged_in": "Оставаться в системе",
    "termsOfUse": "Условия использования.",
    "reset_password": "Сбросить пароль",
    "firstname": "Имя",
    "lastname": "Фамилия",
    "repeat_password": "Повторите пароль",
    "send_code": "Отправить код",
    "code": "код",
    "confrim_lable": "Код отправлен Вам в телеграм или SMS"
  },
  "404": {
    "title": "Эта страница ушла на рыбалку.",
    "text": "Если вы считаете, что это не так, пожалуйста, отправьте нам сообщение на ",
    "back_button": "Вернуться на главную"
  },
  "typography": {
    "primary": "Основные текстовые стили",
    "secondary": "Вторичные текстовые стили"
  },
  "orders": {
    "title": "Заказы",
    "select_status": "Выберите статус",
    "new_chat": "Начать чат с покупателем",
    "search_types": {
      "archive": "Искать в архиве",
      "ttn": "Номер заказа или ТТН",
      "receiver": "Имя получателя для поиска",
      "receiver_phone": "Номер телефона получателя для поиска",
      "order_id": "Номер заказа для поиска"
    },
    "detail": {
      "products_item": "Товары в заказе",
      "not_full": "Не полный заказ",
      "order_number": "Номер заказу",
      "order_date": "Дата заказу",
      "order_sum": "Сумма заказу",
      "payment_method": "Выберите способ оплаты",
      "delivery_method": "Выберите способ доставки",
      "recipient": "Получатель",
      "telephone": "Номер телефона",
      "order_payment_method": "Метод оплаты",
      "ttn": "ТТН заказа",
      "ttn_return": "ТТН  заказа",
      "delivery_address": "Доставка",
      "comment": "Комментарий при заказе",
      "sum": "Сумма",
      "ttn_sum": "Сумма наложенного платежа",
      "earnings": "Заработок",
      "shelves": "Полка:"
    },

    "versions": "Версии",
    "setupRemoteConnections": "Настройка удаленных соединений",
    "currentVisitors": "Текущие посетители",
    "navigationLayout": "Макет навигации",
    "topBarButton": "Верхняя панель",
    "sideBarButton": "Боковая панель"
  },
  "balance": {
    "title": "Информация о балансе",
    "balance_up": "Пополнить",
    "balance_to_pay": "Доступно к выплате",
    "transactions": {
      "title": "Транзакции",
      "status_pay": "Пополнение баланса"
    }
  },

  "payouts": {
    "title": "Выплаты",
    "search_by_ttn": "Поиск по ТТН",
    "phone": "Введите телефон",
    "total_amount": "Общая сумма для выплаты",
    "no_data": "Выплат нет",
    "pay": "Выплатить",

    "transaction": {
      "all": "Все транзакции пользователя",
      "title": "Транзакция",
      "archive": "Архив",
      "balance_to_pay": "Баланс к выплате",
      "order": "Заказ",
      "amount": "Сумма",
      "description": "Описание",
      "paymentOrder": "Оплата заказа",
      "paymentBalance": "Оплата балансом",
      "refund": "Возмещение",
      "system": "Системное начисление",
      "date": "Дата",
      "amout": "Сумма наложенного платежа",
      "price_site": "Наша цена на сайте",
      "earn": "Заработок"
    },
    "userDetails": {
      "fullName": "ФИО",
      "cardNumber": "Номер карты",
      "phoneNumber": "Номер телефона",
      "email": "Email",
      "dropshipping": "Дропшиппинг"
    }
  },
  "payment_methods": {
    "title": "Методы оплаты",
    "mono_bank": {
      "title": "Моно Банк",
      "description": "Используйте API ключ Моно банка для безопасных платежей."
    },
    "payment_by_requisites": {
      "title": "Оплата по реквизитам",
      "description": "Введите реквизиты для завершения оплаты заказа.",
      "lable": "Реквизиты"
    },
    "balance_payment": {
      "title": "Оплата балансом",
      "description": "Оплачивайте заказы с помощью доступного баланса."
    },
    "cash_on_delivery": {
      "title": "Наложенный платеж",
      "description": "Оплата при доставке с указанием суммы."
    }
  },
  "products": {
    "title": "Продукты",
    "sizes_list": "Список Ростовок",
    "sizes": "Ростовки",
    "size": "Размер",
    "actions": "Действия",
    "import": "Импорт",
    "imports_list": "Список импорта",
    "imports": "Импорт Продуктов",
    "import_tabel": {
      "file_name": "Имя файла",
      "total": "Общее количество",
      "file_url": "URL файла",
      "status": "Статус",
      "status_values": {
        "finished": "Завершено",
        "in_progress": "В процессе",
        "failed": "Ошибка"
      }
    },
    "import_statuses": {
      "waiting_products": "Ожидание продукта",
      "failed_products": "Неудачные продукты",
      "total_products": "Общее количество продуктов"
    },
    "product": "Продукт",
    "form": {
      "name": "имя",
      "description": "описание",
      "model": "артикул товара",
      "price": "цена РРЦ",
      "drop_price": " Дроп цена",
      "price_pre_pay": "цена предоплаты",
      "option": "опция",
      "value": "Размер",
      "quantity": "количество",
      "add_image": "Добавить изображение",
      "image": {
        "title": "Добавляйте свои фото и видео, чтобы ваш товар был лучше представлен.",
        "drop": "перетащите файлы сюда для загрузки",
        "des": " Загрузите видео вашего товара, чтобы предоставить потенциальным покупателям больше информации о нём. Видео может помочь лучше продемонстрировать особенности, функционал и преимущества вашего продукта. Поддерживаются форматы MP4, AVI, MOV. Максимальный размер файла – 100 МБ."
      }
    },
    "tabs": {
      "main": "Основные",
      "sizes": "Размеры",
      "photo": "Фото"
    }
  },
  "articles": {
    "title": "Информационные статьи",
    "article": "Статья"
  },
  "settings": {
    "title": "Настройки",
    "confirm": "Подтвердить аккаунт",
    "general": "Общие предпочтения",
    "theme": "Тема"
  },
  "store": {
    "title": "Мои магазины",
    "add_store": "Добавление магазина",
    "edite_store": "Редактировать магазин",
    "emty_store": "У вас нет магазинов",
    "article": {
      "title": "Статьи",
      "form": {
        "name": {
          "lable": "Заголовок",
          "title": "Заголовок",
          "required": "Заголовок не может быть пустой."
        },
        "show_in_header": {
          "lable": "Показать в верху"
        },
        "show_in_footer": {
          "lable": "Показать с низу"
        },
        "sort_order": {
          "lable": "Порядок Сортировки",
          "title": "Порядок Сортировки",
          "required": "Порядок Сортировки не может быть пустой.",
          "format": "Порядок сортировки должен быть цифр"
        },
        "description": {
          "lable": "Описания",
          "title": "Ваше Описания",
          "required": "Описания не может быть пустым."
        }
      }
    }
  },
  "language": {
    "title": "Язык",
    "brazilian_portuguese": "Португальский (Бразилия)",
    "english": "Английский",
    "spanish": "Испанский",
    "simplified_chinese": "Упрощенный китайский",
    "persian": "Персидский"
  },
  "menu": {
    "auth": "Аутентификация",
    "buttons": "Кнопки",
    "timelines": "Временные линии",
    "orders": "Заказы",
    "billing": "Выставление счетов",
    "login": "Войти",
    "articles": "Информационные статьи",
    "chat": "Чаты",
    "preferences": "Настройки аккаунта",
    "payments": "Платежи",
    "payouts": "Выплаты",
    "balance": "Баланс",
    "settings": "Настройки",
    "settings-app": "Настройки приложения",
    "pricing-plans": "Тарифные планы",
    "payment-methods": "Методы оплаты",
    "signup": "Регистрация",
    "recover-password": "Восстановление пароля",
    "recover-password-email": "Восстановление пароля по эл. почте",
    "404": "404",
    "faq": "FAQ",
    "nova": "Нова Почта",
    "nova-api": "API",
    "nova-sender": "Отправитель",
    "users": "Пользователи",
    "products": "Продукты",
    "stores": "Магазины"
  },
  "messages": {
    "all": "Просмотреть все сообщения",
    "new": "Новые сообщения от {name}",
    "mark_as_read": "Отметить как прочитанное"
  },
  "navbar": {
    "messageUs": "Вопросы по веб-разработке:",
    "repository": "GitHub Репозиторий"
  },
  "notifications": {
    "title": "Уведомления, которые вы получаете",
    "email": {
      "title": "Получать Email сообщения",
      "subtitle": "Информационные письма с актуальной информацией о новинках, акциях и т.п."
    },
    "push": {
      "title": "Push Сообщение",
      "subtitle": ""
    },
    "product": {
      "title": "Уведомления по товарам",
      "subtitle": "Інформаційні листи з актуальною інформацією щодо новинок, акцій тощо"
    },
    "order": {
      "title": "Уведомление о заказе",
      "subtitle": "Информация о текущем статусе заказа"
    },
    "system": {
      "title": "Системные уведомления",
      "subtitle": "Уведомления о текущих обновлениях или изменениях в приложении"
    },
    "all": "Просмотреть все уведомления",
    "less": "Показать меньше уведомлений",
    "mark_as_read": "Отметить как прочитанное",
    "sentMessage": "отправил вам сообщение",
    "uploadedZip": "загрузил новый Zip-файл с {type}",
    "startedTopic": "начал новую тему"
  },
  "user": {
    "balance": "Баланс",
    "language": "Изменить язык",
    "logout": "Выйти",
    "profile": "Профиль",
    "settings": "Настройки",
    "billing": "Выставление счетов",
    "faq": "FAQ",
    "helpAndSupport": "Помощь и поддержка",
    "projects": "Проекты",
    "account": "Аккаунт",
    "explore": "Исследовать"
  },
  "treeView": {
    "basic": "Базовый",
    "icons": "Иконки",
    "selectable": "Выбираемый",
    "editable": "Редактируемый",
    "advanced": "Продвинутый"
  },
  "chat": {
    "title": "Чат",
    "sendButton": "Отправить",
    "empty_chats": "У вас пока нету чатов.",
    "not_selected": "Чат не выбран"
  },
  "apiKeys": {
    "title": "API ключи",
    "listTitle": "Список API ключей",
    "addApiButton": "Добавить API",
    "saveApiButton": "Сохранить",
    "addApiTitle": "Добавить API",
    "editApiTitle": "Редактировать API",
    "description": "Описание"
  },
  "senders": {
    "api_key": "API ключ",
    "sender": "Отправитель",
    "contact_person": "Контактное лицо",
    "region": "Область",
    "city": "Город",
    "branch": "Отделение",
    "sendButton": "Отправить"
  },
  "spacingPlayground": {
    "value": "Значение",
    "margin": "Отступ",
    "padding": "Внутренний отступ"
  },
  "spacing": {
    "title": "Отступы"
  },
  "cards": {
    "cards": "Карты",
    "fixed": "Фиксированные",
    "floating": "Плавающие",
    "contentText": "Уникальные полосы зебр делают их одним из самых знакомых животных для людей.",
    "contentTextLong": "Уникальные полосы зебр делают их одним из самых знакомых животных для людей. Они встречаются в различных местах обитания, таких как луга, саванны, лесные массивы, колючие кустарники, горы и прибрежные холмы. Различные антропогенные факторы оказали серьезное воздействие на популяции зебр, в частности, охоту на шкуры и уничтожение мест обитания. Зебры Греви и горные зебры находятся под угрозой исчезновения. В то время как обычные зебры гораздо более распространены, один подвид, квагга.",
    "rowHeight": "Высота строки",
    "title": {
      "default": "По умолчанию",
      "withControls": "С элементами управления",
      "customHeader": "Свои заголовки",
      "withoutHeader": "Без заголовка",
      "withImage": "С изображением",
      "withTitleOnImage": "С заголовком на изображении",
      "withCustomTitleOnImage": "Со своим заголовком на изображении",
      "withStripe": "С полосой",
      "withBackground": "С фоном"
    },
    "button": {
      "main": "Основная",
      "cancel": "Отмена",
      "showMore": "Показать больше",
      "readMore": "Показать больше"
    },
    "link": {
      "edit": "Редактировать",
      "setAsDefault": "Установить по умолчанию",
      "delete": "Удалить",
      "traveling": "Путешествие",
      "france": "Франция",
      "review": "Обзор",
      "feedback": "Оставить отзыв",
      "readFull": "Читать полную статью",
      "secondaryAction": "Вторичное действие",
      "action1": "Действие 1",
      "action2": "Действие 2"
    }
  },
  "colors": {
    "themeColors": "Цвета темы",
    "extraColors": "Дополнительные цвета",
    "gradients": {
      "basic": {
        "title": "Градиенты кнопок"
      },
      "hovered": {
        "title": "Градиенты при наведении",
        "text": "Осветление на 15% применяется к исходному стилю (градиент или плоский цвет) для состояния наведения."
      },
      "pressed": {
        "title": "Градиенты при нажатии",
        "text": "Затемнение на 15% применяется к исходному стилю (градиент или плоский цвет) для состояния нажатия."
      }
    }
  },
  "tabs": {
    "alignment": "Выравнивание вкладок",
    "overflow": "Переполнение вкладок",
    "hidden": "Вкладки с скрытым ползунком",
    "grow": "Расширение вкладок"
  },
  "helpAndSupport": "Помощь и поддержка",
  "aboutVuesticAdmin": "О Vuestic Admin",
  "search": {
    "placeholder": "Поиск..."
  },
  "buttonSelect": {
    "dark": "Темный",
    "light": "Светлый"
  },
  "validation": {
    "required": "Это поле обязательно"
  },
  "system": {
    "edited": "отредактировано",
    "no_data": "нет данных",
    "confirmed": "Підтверджено",
    "button": {
      "confirm": "Подтверждать",
      "back": "Назад",
      "close": "Закрыть",
      "next": "Продолжить",
      "add": "Добавлять",
      "filter": "Фильтр",
      "clear": "Очистить",
      "cancel": "Отмена",
      "edit": "Редактировать",
      "save": "Сохранять"
    },
    "alert": {
      "code": "На ваш номер отправлен код, пожалуйста подтвердите",
      "phone": {
        "confirmed": "Номер потверждён"
      }
    }
  }
}
